import { request } from '@midwayjs/hooks/request'


export  function signIn (...args) {
  return request({
    url: '/api/sign/_signIn',
    method: 'POST',
    data: {
      args: args,
    },
    meta: {
  "functionName": "sign-index-signin",
  "functionGroup": "midway-faas-react-demo",
  "gateway": "http"
}
  })
}

export  function signOn (...args) {
  return request({
    url: '/api/sign/_signOn',
    method: 'POST',
    data: {
      args: args,
    },
    meta: {
  "functionName": "sign-index-signon",
  "functionGroup": "midway-faas-react-demo",
  "gateway": "http"
}
  })
}

