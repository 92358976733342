import { request } from '@midwayjs/hooks/request'


export  default  function create (...args) {
  return request({
    url: '/api/tiqin/create',
    method: 'POST',
    data: {
      args: args,
    },
    meta: {
  "functionName": "tiqin-create",
  "functionGroup": "midway-faas-react-demo",
  "gateway": "http"
}
  })
}

